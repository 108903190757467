#tenant-edit {
    .info-domain-message {
        margin-top: 17px;
    }

    .ssl-info {
        margin-top: calc(5px - $input-margin-bottom);
        
        span.success {
            color: green;
        }

        span {
            color: #686868;
        }
    }

    .domain-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: calc(100% - $input-margin-bottom);

        > p:first-child {
            font-weight: 500;
        }

        > p:nth-child(2) {
            color: #686868;
            font-size: 14px;
            margin-top: 2px;
        }
    }

    .tenant-logo {
        position: relative;
        width: 100%;
        max-width: 150px;
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }

    .tenant-favicon {
        position: relative;
        width: 100%;
        max-width: 32px;
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }

    .tenant-logo, .tenant-favicon {
        button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            right: -25px;
            top: -5px;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: red;
            color: #fff;
            border: none;
        }
    }
}