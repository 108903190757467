.select-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    .select-label {
        display: flex;
        align-items: center;
        font-size: 13px;
        img {
            margin-right: 5px;
            width: 25px;
            height: 25px;
            object-fit: cover;
        }
    }

    label {
        font-size: $input-label-font-size;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 10px;
        background-color: #fff;
        padding: 0 10px;
        top: -8px;
        z-index: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;

        .help-icon {
            background-color: #76709d;
            color: #fff;
            font-size: 10px;
            width: 11px;
            height: 11px;
            display: inline-block;
            text-align: center;
            border-radius: 100%;
            margin-left: 5px;
        }
    }
}