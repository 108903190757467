#panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .wrapper {
        overflow-y: auto;
        width: calc(100% - $sidebar-width);
        height: 100vh;
        padding: $elements-margin;
    }
}