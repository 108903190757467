.box {
    margin-bottom: $elements-margin;

    &.with-pagination {
        padding: 0;
        max-height: calc(100vh - 170px);
        overflow-y: auto;
    }

    &.with-pagination.with-filter-inline {
        padding: 0;
        max-height: calc(100vh - 205px);
        overflow-y: auto;
    }

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;

        span {
            color: $text-secondary-color;
            font-weight: 500;
        }
    }

    .body {
        background-color: $white-color;
        padding: 15px;
        border: solid 1px $box-border-color;
        border-radius: 3px;
        height: 100%;
    }

    &.no-padding {
        .body {
            padding: 0;
        }
    }
}