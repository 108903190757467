.input-checkbox-unique {
    display: flex;
    border: none;
    background-color: transparent;
    align-items: center;
    margin-bottom: $input-margin-bottom;

    &.disabled {
        opacity: .2;
    }
}