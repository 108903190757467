.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: $sidebar-width;
    z-index: 9;
    background-color: $bg-page;
    opacity: .5;

    &.hide {
        display: none;
    }
}